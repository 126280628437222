/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { getLocalStorage } from '../../utils/localstorage';
import axiosInstance from '../../axiosConfig';
import EmailIcon from '@mui/icons-material/Email';
import TagIcon from '@mui/icons-material/Tag';
import LockIcon from '@mui/icons-material/Lock';
import DeleteIcon from '@mui/icons-material/Delete';
import Input from '../common/Input';
import PasswordField from '../common/PasswordField';
import { Button, Snackbar, Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { ClipLoader } from 'react-spinners';
import { networks, nodeImages } from '../common/Networks';
import HelpIcon from '@mui/icons-material/Help';
import CellWifiIcon from '@mui/icons-material/CellWifi';
import HelptoConnect from '../../assets/images/HelptoConnect.png'
import SavetoConnect from '../../assets/images/SavetoConnect.png'
import Select from '../../assets/images/select.png'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { findUnconnectedNodes } from '../common/NodeUtils';
import { pushDataLayerEvent } from '../../utils/dataLayer';

export const fetchAllCredentials = async () => {
  const user = getLocalStorage('user');
  if (!user) return null;

  try {
    const response = await axiosInstance.get('/credential/', {
      headers: {
        Authorization: `Bearer ${getLocalStorage('token')}`,
      },
    });
    return response.data.response;
  } catch (error) {
    console.error('Error fetching node data:', error);
    return null;
  }
};

const OtherNodes = () => {
  const [nodes, setNodes] = useState([]);
  const [credentialsState, setCredentialsState] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [addMoreCredentialsNode, setAddMoreCredentialsNode] = useState('')
  const [loading, setLoading] = useState(false)
  const [globalError, setGlobalError] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpenMoreThanZeroNodes, setDialogOpenMoreThanZeroNodes] = useState(false);
  const [dialogOpenAllConnected, setDialogOpenAllConnected] = useState(false);
  const [allNodesConnected, setAllNodesConnected] = useState(false);

  const setInternalId = (credentials) => {
    let id = 0;
    for (const credential of credentials) {
      credential.internalId = id;
      id++;
    }
    return credentials;
  }

  const clearAllErrors = () => {
    const newCredentials = [...credentialsState];
    for (const credential of newCredentials) {
      credential.error = ''
    }
    setCredentialsState(newCredentials)
    setGlobalError("")
  }

  const addMoreCredentials = () => {
    if (!addMoreCredentialsNode) return
    const newCredentials = [...credentialsState];
    newCredentials.push({
      email: '',
      password: '',
      amount: 0,
      network: addMoreCredentialsNode
    })
    setCredentialsState(setInternalId(newCredentials))
    pushDataLayerEvent('add_button_clicked', {
      category: 'Button',
      action: 'Click',
      label: 'Add More Credentials'
    });
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const data = await fetchNodeData();
      const nodes = [];
      const nodeExists = new Set();
      for (const node of data) {
        if (node.paymentStatus.isPayed === false) continue
        if (nodeExists.has(node.instanceNetwork.networkName)) continue
        nodes.push(node.instanceNetwork.networkName)
        nodeExists.add(node.instanceNetwork.networkName)
      }
      const credentials = await fetchAllCredentials();
      setCredentialsState(setInternalId(credentials))
      setNodes(nodes.sort((a, b) => networks.indexOf(a) - networks.indexOf(b)));
      setLoading(false)
    };
    fetchData();
  }, []);

  const updateCredentialStateProperty = (internalId, propertyName, propertyValue) => {
    const newCredentialsState = credentialsState.map(credential => {
      if (credential.internalId === internalId) {
        return {
          ...credential,
          [propertyName]: propertyValue
        }
      }
      return credential
    })
    setCredentialsState(setInternalId(newCredentialsState))
  }

  const getTotalAmount = () => {
    const newNodes = credentialsState.filter(credential => credential._id === undefined);
    return newNodes.reduce((total, credential) => total + parseInt(credential.amount || 0), 0);
  };


  const updateCredentials = async () => {
    const response = await axiosInstance.post('/credential/updateCredentials', {
      credentialsState
    }, {
      headers: {
        Authorization: `Bearer ${getLocalStorage('token')}`,
      },
    }
    );

    const newCredentials = [...credentialsState];
    for (const error of response.data.errors) {
      if (error.internalId === undefined) {
        setGlobalError(error.message)
      } else {
        for (const credential of newCredentials) {
          if (credential.internalId === error.internalId) {
            credential.error = error.message
          }
        }
      }
    }
    setCredentialsState(newCredentials)
    if (response.data.errors.length > 0) return;

    clearAllErrors()
    if (getTotalAmount() > 0) {
      setSnackbarOpen(true)
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    pushDataLayerEvent('update_credentials', {
      category: 'Form',
      action: 'Submit',
      label: 'Update Credentials',
      totalAmount: getTotalAmount()
    });
  }

  const getCredentialStateById = (internalId) => {
    return credentialsState.find(credential => credential.internalId === internalId)
  }

  const fetchNodeData = async () => {
    const user = getLocalStorage('user');
    if (!user) return null;

    try {
      const response = await axiosInstance.get('/node/getNodes', {
        headers: {
          Authorization: `Bearer ${getLocalStorage('token')}`,
        }
      });
      return response.data.response;
    } catch (error) {
      console.error('Error fetching node data:', error);
      return null;
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseDialogMoreThanZeroNodes = () => {
    setDialogOpenMoreThanZeroNodes(false);
  };

  const handleCloseDialogMAllConnected = () => {
    setDialogOpenAllConnected(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const unconnectedNodes = await findUnconnectedNodes();

      const totalPendingNodes = unconnectedNodes.reduce((total, node) => total + node.remainingAmount, 0);
      const allNodesConnected = totalPendingNodes === 0;
      setAllNodesConnected(allNodesConnected);
      pushDataLayerEvent('node_connection_status', {
        category: 'Status',
        action: 'Check',
        label: 'Node Connection Status',
        allConnected: allNodesConnected
      });
    };
    fetchData();
  }, []);

  const handleHelpClick = () => {
    if (nodes.length === 0) {
      setDialogOpen(true);
    } else {
      if (allNodesConnected) {
        setDialogOpenAllConnected(true);
      } else {
        setDialogOpenMoreThanZeroNodes(true);
      }
    }
    pushDataLayerEvent('help_button_clicked', {
      category: 'Button',
      action: 'Click',
      label: 'Help'
    });
  };

  const confirmDelete = (internalId) => {
    deleteCredetenialStateById(internalId);
  };

  const deleteCredetenialStateById = async (internalId) => {
    const newCredentials = [...credentialsState];
    const index = newCredentials.findIndex(credential => credential.internalId === internalId);
    newCredentials.splice(index, 1);
    setCredentialsState(newCredentials);
  };

  const handleButtonClick = () => {
    updateCredentials();
    pushDataLayerEvent('save_configurations_clicked', {
      category: 'Button',
      action: 'Click',
      label: 'Save Configurations'
    });
  };


  return (
    <>
      {
        !loading ?
          <><div className='profile bottom-[3%] sm:bottom-[5%] relative py-0 px-4 sm:ml-6' style={{ minHeight: 'fit-content' }}>
            <h2 className='mb-2 text-white uppercase text-start'>Connect Your Nodes</h2>
            {!allNodesConnected ? (
              <div className='flex items-center flex-row justify-start InfoHere pb-2'>
                <InfoOutlinedIcon className='text-white mr-2 text-xl' /><p id='fonFont'>The hosts that you purchased are not connected to your nodes . Please activate your host now.</p>
              </div>
            ) : <></>}

            <div className='flex flex-col sm:flex-row justify-between items-center pb-2'>
              <div className='w-full sm:w-[70%]'>
                <span className='mb-2 text-white uppercase text-start' id="fonFont" style={{ paddingRight: 10 }}>ADD NETWORK</span>
                <select
                  onChange={(e) => { setAddMoreCredentialsNode(e.target.value); }}
                  className='bg-transparent border-b border-[#F55937] py-1  text-[12px] sm:text-[18px] text-white' style={{ marginRight: 10 }}>
                  <option value="">Select Node</option>
                  {nodes.map((node, index) => {
                    const selected = addMoreCredentialsNode === node;
                    return (
                      <option key={index} value={node} selected={selected}>{node}</option>
                    );
                  })}
                </select>
                <Button
                  variant="contained"
                  size="medium"
                  style={{ backgroundColor: '#F55937', color: 'white', padding: '0 3px' }}
                  onClick={() => { addMoreCredentials(); }}
                >Add
                </Button>
              </div>
              <div className='flex flex-row justify-end w-full mt-4 sm:w-[33%]'>
                <div className='hidden sm:block'>
                  <Button
                    variant="contained"
                    size="medium"
                    className={`text-xs sm:text-xl rounded-lg`}
                    style={{ backgroundColor: '#F55937', color: 'white' }}
                    onClick={handleButtonClick}
                  >
                    <CheckCircleOutlineIcon className='text-white mr-2' />
                    Save Configuration
                  </Button>
                </div>
                <div className='HostButton ml-3 flex flex-row justify-between items-center px-3 border-2 border-[#F55937] rounded-lg cursor-pointer' onClick={handleHelpClick}>
                  <HelpIcon className='text-[#F55937] mr-1' />
                  <p>Help?</p>
                </div>
              </div>
            </div>
            <div className='mb-4 text-white uppercase text-start' style={{ paddingRight: 10, color: "red", marginBottom: 20 }}>{globalError}</div>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MuiAlert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                Nodes connected successfully!
              </MuiAlert>
            </Snackbar>
            <div className="flex flex-col-reverse sm:flex-row flex-wrap -mx-2 pb-8 sm:pb-0 ">
              {credentialsState.map((credential, index) => {
                return (
                  <div key={index} className={`w-[96%] xs:w-1/2 md:w-[21%] lg:w-[21%] xl:w-[21%] px-2 mb-4 py-2 m-2 sm:m-4 rounded-lg ${credential._id ? 'border-button-container' : 'border-button-container-white'}`}>
                    <div className='flex w-full justify-end delete-icon'>
                      <DeleteIcon onClick={() => confirmDelete(credential.internalId)} sx={{ color: "#F55937" }} style={{ float: "right", cursor: 'pointer' }} />
                    </div>
                    <div className='flex w-full justify-center'>
                      <img src={nodeImages[credential.network]} className='w-[30%] sm:w-[25%] h-[12%] justify-center items-center' alt="Node" />
                    </div>
                    <div style={{ color: "red", marginTop: 10, marginBottom: 10 }}>
                      {getCredentialStateById(credential.internalId).error}
                    </div>
                    <div className="w-full mb-1 sm:mb-0 inputTT">
                      <Input
                        value={credential.email}
                        onChange={(e) => {
                          updateCredentialStateProperty(credential.internalId, 'email', e.target.value);
                        }}
                        placeholder="email"
                        mt="mt-1"
                        p="p-1"
                        icon={<EmailIcon className="text-black" />} />
                      <PasswordField
                        value={getCredentialStateById(credential.internalId).password}
                        onChange={(e) => {
                          updateCredentialStateProperty(credential.internalId, 'password', e.target.value);
                        }}
                        placeholder="•••••••••"
                        mt="mt-1"
                        p="p-1"
                        icon={<LockIcon className="text-black" />} />
                      <Input
                        value={getCredentialStateById(credential.internalId).amount !== 0 ? getCredentialStateById(credential.internalId).amount : ''}
                        onChange={(e) => {
                          updateCredentialStateProperty(credential.internalId, 'amount', e.target.value);
                        }}
                        placeholder="You must add at least 1"
                        mt="mt-1"
                        p="p-1"
                        icon={<TagIcon className="text-black" />} />

                    </div>
                  </div>
                );
              })}
            </div>
            <Dialog
              open={dialogOpen}
              onClose={handleCloseDialog}
              aria-labelledby="help-dialog-title"
              aria-describedby="help-dialog-description"
            >
              <DialogTitle className='flex flex-start' id="help-dialog-title"><CellWifiIcon className='text-white mr-2' />Connect Your Nodes</DialogTitle>
              <DialogContent>
                <p>
                  You have <b> 0 </b>hosts rented.
                </p>
                <p className='my-2'>
                  In order to be able to connect your nodes you have to first rent hosts.
                </p>
                <p>
                  Please click <a className='text-[#F55937]' href='/hosting'><b>here</b></a> to start
                </p>

              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  <p>Close</p>
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={dialogOpenMoreThanZeroNodes}
              onClose={handleCloseDialogMoreThanZeroNodes}
              aria-labelledby="help-dialog-title-more-than-zero-nodes"
              aria-describedby="help-dialog-description-more-than-zero-nodes"
            >
              <DialogTitle className='flex flex-start' id="help-dialog-title"><CellWifiIcon className='text-white mr-2' />Connect Your Nodes</DialogTitle>
              <DialogContent>
                <p>
                  You have <b> {nodes.length} </b>hosts rented. Now, you need to connect these hosts with the nodes you want to host:
                </p>

                <div className='flex flex-col justify-center mx-[15%] sm:mx-[25%] my-[2%]'>
                  <p>
                    <span className='text-[#F55937]'>
                      <b>STEP 1</b>
                    </span>
                  </p>
                  <p>Please, add the nodes you want to connect</p>
                  <div className='flex'>
                    <img className='w-full sm:w-[80%]' src={Select} alt="AISD Server Logo" />
                  </div>
                  <p>
                    <span className='text-[#F55937]'>
                      <b>STEP 2</b>
                    </span>
                  </p>
                  <p>Enter nodes credentials to connect them.
                    You must add at least 1 Node  in the # field. </p>
                  <div className='flex'>
                    <img className='w-full sm:w-[60%]' src={HelptoConnect} alt="AISD Server Logo" />
                  </div>
                  <p>
                    <span className='text-[#F55937]'>
                      <b>STEP 3</b>
                    </span>
                  </p>
                  <p>Save your changes clicking.</p>
                  <div>
                    <img className='w-full sm:w-[50%]' src={SavetoConnect} alt="AISD Server Logo" />
                  </div>
                </div>

              </DialogContent>
              <DialogActions id='onlyHere'>
                <p>*To see the data updated could take a few minutes</p>
                <Button onClick={handleCloseDialogMoreThanZeroNodes} className='text-[#F55937]'>
                  <p>Close</p>
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={dialogOpenAllConnected}
              onClose={handleCloseDialogMAllConnected}
              aria-labelledby="help-dialog-title-more-than-zero-nodes"
              aria-describedby="help-dialog-description-more-than-zero-nodes"
            >
              <DialogTitle className='flex flex-start' id="help-dialog-title"><CellWifiIcon className='text-white mr-2' />Connect Your Nodes</DialogTitle>
              <DialogContent>
                <p>
                  You have <b> {nodes.length} </b> hosts rented and  all your nodes have been connected to our hosts!
                </p>
                <p className='my-2'>
                  Visit our hosting page to rent more hosts
                </p>
                <p>
                  Please click <a className='text-[#F55937]' href='/hosting'><b>here</b></a> to start
                </p>

              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialogMAllConnected} className='text-[#F55937]'>
                  <p>Close</p>
                </Button>
              </DialogActions>
            </Dialog>

          </div>
            <div className="block fixed bottom-0 left-0 text-center text-white right-0 bg-[#999999] p-4 z-[1300] sm:hidden" style={{ backgroundColor: '#F55937', color: 'white' }}>
              <button onClick={handleButtonClick}
              >
                <CheckCircleOutlineIcon className='text-white mr-2' />
                Save all Configurations

              </button>
            </div>
          </>
          : <ClipLoader color='#f55937' />
      }
    </>
  )
};

export default OtherNodes;