import React, { useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import DrawerLogo from '../assets/images/drawer_logo.png';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Divider } from '@mui/material';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PasswordIcon from '@mui/icons-material/Password';
import LogoutIcon from '@mui/icons-material/Logout';
import { useHistory, useLocation } from 'react-router-dom';
import CampaignIcon from '@mui/icons-material/Campaign';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import WalletIcon from '@mui/icons-material/Wallet';
import Badge from '@mui/material/Badge';
import { findUnconnectedNodes } from '../components/common/NodeUtils';
import DescriptionIcon from '@mui/icons-material/Description';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { pushDataLayerEvent } from '../utils/dataLayer';
import { jwtDecode } from 'jwt-decode';
import TruthIcon from '../assets/images/hosting/TRUU.svg';

const drawerWidth = 210;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const tabList = [
  { text: 'Dashboard', path: '/dashboard', icon: <SpaceDashboardIcon /> },
  { text: 'Truth Nodes', path: '/truth-node', icon: <img src={TruthIcon} alt="Truth Icon" style={{ width: 24, height: 24 }} /> },
  { text: 'Network', path: '/network', icon: <NetworkCheckIcon /> },
  { text: 'Wallet', path: '/wallet', icon: <WalletIcon /> },
  { text: 'Marketing Tools', path: '/marketing-tools', icon: <CampaignIcon /> },
  { text: 'Hosting', path: '/hosting', icon: <SignalCellularAltIcon /> },
  { text: 'Nodes Account', path: '/nodes-account', icon: <PasswordIcon /> },
  { text: 'Billing', path: '/bills', icon: <DescriptionIcon /> },
  { admin: true, text: 'All Bills', path: '/all-bills', icon: <DescriptionIcon /> },
  { admin: true, text: 'Withdraws', path: '/withdraws', icon: <DescriptionIcon /> },
  { text: 'Payment Methods', path: '/payment-methods', icon: <CreditCardIcon /> },
  { text: 'Subscriptions', path: '/subscriptions', icon: <LoyaltyIcon /> },
  { text: 'Profile', path: '/profile', icon: <PersonOutlineIcon /> },
  { text: 'Logout', path: '/login', icon: <LogoutIcon /> }
];

const TheSidebar = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(window.innerWidth > 1050);
  const [selectedOption, setSelectedOption] = React.useState('Dashboard');

  const location = useLocation();
  const history = useHistory();

  const [totalPendingNodes, setTotalPendingNodes] = React.useState(0); // State for total pending nodes

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if(token){
        const unconnectedNodes = await findUnconnectedNodes();
        const totalPendingNodes = unconnectedNodes.reduce((total, node) => total + node.remainingAmount, 0);
        setTotalPendingNodes(totalPendingNodes); // Set the state here
      }
    };
    fetchData();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    for (const tab of tabList) {
      if (location.pathname === tab.path) {
        setSelectedOption(tab.text);
      }
    }
  }, [location]);

  const clearSelectiveLocalStorage = () => {
    const cookieConsent = localStorage.getItem('cookie-consent');
    localStorage.clear();
    if (cookieConsent) {
      localStorage.setItem('cookie-consent', cookieConsent);
    }
  };

  const handleListItemClick = (text, path) => {
    if (text === 'Logout') {
      clearSelectiveLocalStorage();
    }
    history.push(path);
    pushDataLayerEvent('sidebar', {
      category: 'Sidebar',
      action: 'Click',
      label: text,
    });
  };

  const token = localStorage.getItem('token');
  let decodedToken = null;

  if (token) {
    try {
      if (typeof token === 'string') {
        decodedToken = jwtDecode(token);
      } else {
        console.error('Invalid token: must be a string');
      }
    } catch (error) {
      console.error('Error decoding token:', error);
    }
  }

  return (
    <>
      <AppBar position="fixed" open={open} sx={{ boxShadow: 'none!important', background: 'none!important' }}>
        <Toolbar
          className='flex justify-around bg-[#2F292B] AisdMenu'
          sx={{
            padding: '0!important',
            ...(open && { display: 'none' })
          }}>
          <Box sx={{
            backgroundColor: '#2F292B!important',
          }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                ...(open && { display: 'none' }),
              }}
            >
              <img src={DrawerLogo} alt="AISD Server Logo" className='cursor-pointer' />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <div className='flex justify-around w-full items-center'>
            <img src={DrawerLogo} alt="AISD Server Logo" className='cursor-pointer' />
            <h2>AISD</h2>
            <IconButton onClick={handleDrawerClose} className='text-white'>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
        </DrawerHeader>
        <Divider sx={{
          margin: '0 8px!important'
        }} />
        <List>
          {tabList.map((tab, index) => {
            if (tab.admin && decodedToken?.option?._id !== '662fd9808ac709ae421583cd') return null;

            return (
              <ListItem key={tab.text} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  selected={selectedOption === tab.text}
                  onClick={() => handleListItemClick(tab.text, tab.path)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    ...(selectedOption === tab.text && {
                      color: '#fff!important',
                      backgroundColor: 'transparent!important'
                    }),
                    ...(!(selectedOption === tab.text) && {
                      color: '#999999!important',
                    }),
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                      ...(selectedOption === tab.text && {
                        color: '#fff!important',
                      }),
                      ...(!(selectedOption === tab.text) && {
                        color: '#999999!important',
                      }),
                    }}
                  >
                    {tab.icon}
                  </ListItemIcon>
                  <ListItemText primary={tab.text} sx={{ opacity: open ? 1 : 0 }} />
                  {tab.text === 'Nodes Account' && totalPendingNodes > 0 && (
                    <Badge badgeContent={totalPendingNodes} />
                  )}
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Drawer>
    </>
  );
};

export default React.memo(TheSidebar);
