// NodeUtils.js
import axiosInstance from '../../axiosConfig';
import { getLocalStorage } from '../../utils/localstorage';
import { fetchAllCredentials } from '../nodes-account/OtherNodes'; 

export const findUnconnectedNodes = async () => {
  const fetchNodeData = async () => {
    const user = getLocalStorage('user');
    if (!user) return null;

    try {
      const response = await axiosInstance.get('/node/getNodes', {
        headers: {
          Authorization: `Bearer ${getLocalStorage('token')}`,
        }
      });
      return response.data.response;
    } catch (error) {
      console.error('Error fetching node data:', error);
      return null;
    }
  };
  const nodes = await fetchNodeData();
  const credentials = await fetchAllCredentials();

  if (!nodes || !credentials) {
    console.error('Failed to fetch data');
    return [];
  }

  const user = getLocalStorage('user');
  if (!user) {
    console.error('User not found in local storage');
    return [];
  }

  const purchasedNodes = nodes.filter(node =>
    node.userId === user._id &&
    node.paymentStatus.isPayed
  );

  const networkAmountsMap = new Map();
  purchasedNodes.forEach(node => {
    const networkName = node.instanceNetwork.networkName;
    const amount = node.amount;
    if (networkAmountsMap.has(networkName)) {
      networkAmountsMap.set(networkName, networkAmountsMap.get(networkName) + amount);
    } else {
      networkAmountsMap.set(networkName, amount);
    }
  });

  const credentialsAmountsMap = new Map();
  credentials.forEach(credential => {
    const networkName = credential.network;
    const amount = credential.amount;
    if (credentialsAmountsMap.has(networkName)) {
      credentialsAmountsMap.set(networkName, credentialsAmountsMap.get(networkName) + amount);
    } else {
      credentialsAmountsMap.set(networkName, amount);
    }
  });

  const unconnectedNodes = [];
  networkAmountsMap.forEach((networkAmount, networkName) => {
    if (credentialsAmountsMap.has(networkName)) {
      const remainingAmount = networkAmount - credentialsAmountsMap.get(networkName);
      if (remainingAmount > 0) {
        unconnectedNodes.push({ networkName, remainingAmount });
      }
    } else {
      unconnectedNodes.push({ networkName, remainingAmount: networkAmount });
    }
  });

  return unconnectedNodes;
};
